// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

hr, hr.wp-block-separator {
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','md');
  border-top: 4px solid $green;
}

.wp-block-image,figure.wp-block-image {
  @include paragraph-spacing;
  .aligncenter {
    text-align: center;
  }
  figcaption {
    font-size: $font-size-sm;
    padding-top: 2px;
    text-align: center;
  }
}
.wp-block-pullquote,.wp-block-quote {
  @include color-block('pale-blue-10');
  @include full-padding-spacer('md');
  @include last-child-zero-margin;
  font-size: $font-size-lg;
  text-align: center;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

// Columns

.wp-block-columns {
  display: flex;
  margin-bottom: 1.75em;
  flex-wrap: wrap;
  align-items: inherit !important;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
  &.are-vertically-aligned-top {
    align-items: flex-start;
  }
  .wp-block-column {
    @include scaled-spacer('margin','bottom','sm');
    @include last-child-zero-margin;
    &:last-child {
      margin-bottom: 0;
    }
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
    @include media-breakpoint-up(md) {
//       margin-bottom: 0;
      flex-basis: 0;
      flex-grow: 1;
      &[style*=flex-basis] {
        flex-grow: 0;
      }
      padding: 0 $grid-gutter-width/2;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// Buttons
.wp-block-buttons {
  @include paragraph-spacing;
  .wp-block-button {
    display: inline-block;
    margin-left: $grid-gutter-width/2;
    &:first-child {
      margin-left: 0;
    }
    .wp-block-button__link {
      @extend .btn;
    }
    &.is-style-default-arrow {
      .wp-block-button__link {
        @extend .btn-arrow;
      }
    }
    &.is-style-underline {
      .wp-block-button__link {
        @extend .btn-line;
      }
    }
    &.is-style-underline-arrow {
      .wp-block-button__link {
        @extend .btn-line;
        @extend .btn-arrow;
      }
    }
  }
  &.is-vertical {
    .wp-block-button {
      display: block;
      margin-left: 0;
    }
  }
}

.wp-block-table {
  overflow-x: scroll;
  table {
    @extend .table;
    @extend .table-striped;
    width: 100%;
    th {
      @include color-block('blue');
    }
  }
  figcaption {
    @include last-child-zero-margin;
    font-size: $font-size-sm;
  }
  &.table4 {
    table.has-fixed-layout {
      th,td {
        width: 25%;
      }
    }
  }
}
