html {
  font-size: 13px;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: $header-height-sm*1.25;
  @include media-breakpoint-up(md) {
    font-size: 14px;
    scroll-padding-top: $header-height*1.25;
  }
  @include media-breakpoint-up(lg) {
    font-size: 15px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}
body {
  padding-top: $header-height-sm;
  @include media-breakpoint-up(md) {
    padding-top: $header-height;
  }
}

/* ELEMENTS
  --------------------------------------- */
table {
  width: 100%;
  th {
    @include color-block('blue');
  }
}
img {
  @include img-fluid;
}
.row.vertical-center {
  align-items: center;
}
a {
  @include transition($link-transition);
}
a.lined,.nav-lined a, span.lined {
  display: inline-block;
  text-decoration: none;
  padding: 0 0 .2rem 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: $pale-blue-30;
  font-weight: $font-weight-bold;
  color: $brand-primary;
  @include hover-focus {
    color: $brand-primary;
    border-bottom-color: $brand-primary;
  }
  &.lined-icon {
    i {
      margin-left: $btn-spacer;
    }
  }
}
.page-header-wrap {
  @include color-block('pale-blue-30');
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  @include wp-full-width-no-padding;
  margin: 0;
  .page-header {
    @include main-column-width;
    @include last-child-zero-margin;
    text-align: center;
    font-size: $font-size-lg;
  }
  .single-post & {
    @include vgs-gradient;
    padding: 0;
    .details {
      @include scaled-spacer('padding','top','lg');
      @include scaled-spacer('padding','bottom','lg');
      p.categories {
        @include meta-text;
        a {
          text-decoration: none;
        }
      }
      time {
        @include scaled-spacer('margin','top','md');
        display: block;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
      }
    }
    .container-fluid {
      &>.row {
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
        .details {
          @include clipped-details-post;
        }
        .media {
          @include clipped;
          height: 250px;
          @include media-breakpoint-down(md) {
            clip-path: none;
          }
          @include media-breakpoint-up(sm) {
            height: 360px;
          }
          @include media-breakpoint-up(md) {
            height: 440px;
          }
          @include media-breakpoint-up(lg) {
            height: auto;
            min-height: 440px;
          }
        }
      }
    }

  }
}
.hiddentip {
  display: none;
  visibility: hidden;
  font-size: 0;
}

/* FONTS
  --------------------------------------- */
.overline {
  @include overline;
  @include paragraph-spacing;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  @include paragraph-spacing;
  letter-spacing: -.02em;
}
p,ol,ul,blockquote,.wp-block-image {
  @include paragraph-spacing;
}
main {
  ul,ol {
    @include list-no-indent;
    li {
      margin-bottom: .2em;
      ul,ol {
        @include list-md-indent;
        @include scaled-spacer('margin','top','xs');
      }
    }
  }
  p,ol,ul,blockquote,.wp-block-image {
    @include paragraph-spacing;
  }
  h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    @include scaled-spacer('margin','top','md');
    color: $brand-primary;
  }
  &>:first-child {
    margin-top: 0;
    &:not(.cta-color-banner-block) {
      padding-top: 0;
    }
  }
  &>:last-child {
    margin-bottom: 0;
    &:not(.cta-color-banner-block) {
      padding-bottom: 0;
    }
  }
}
