/* GRID SETTINGS
  --------------------------------------- */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1152px
);
$base-gutter-heights: (
  xs: 13px,
  md: 14px,
  lg: 16px
);
$base-gutter-scales: (
  xs: .75,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 5
);

$grid-gutter-width-base:  30px;
$grid-gutter-width:       $grid-gutter-width-base;

$main-sm-columns:         12;
$sidebar-sm-columns:      4;

$header-height:           80px;
$header-height-sm:        65px;
$adminbar-height:         32px;
$adminbar-height-sm:      46px;
$alertbar-min-height:     36px;
$alertbar-dismiss-width:  $alertbar-min-height/3;

$spacer-fixed:            16px;


/* COLORS
  --------------------------------------- */
$blue:                  #003C7F;
$blue-dark:             #036;
$green:                 #009E4E;
$pale-blue:             #9ABAE2;
$pale-blue-30:          #E1EAF6;
$pale-blue-10:          #F5F8FC;
$gold:                  #FFD940;
$red:                   #EB2146;
$teal:                  #49ACC1;

$white:                 #fff;
$black:                 #000;
$gray:                  #55545A;
$gray-light:            #eee;

$body-color:            $gray;
$body-bg:               $white;

$brand-primary:         $blue;
$brand-secondary:       $green;

$link-color:            $brand-primary;
$link-decoration:       underline;
$link-hover-color:      $green;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-fast:  all .1s ease-in-out;
$link-transition-med:   all .25s ease-in-out;
$link-transition-slow:  all .3s ease-in-out;
$link-transition-pad:   padding .05s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;


/* FONTS
  --------------------------------------- */
$font-family-sans:      "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-title:     "itc-avant-garde-gothic-pro","sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base:      $font-family-sans;

                        // sizes        // xs/sm /   md   /   lg   /   xl
$font-size-base:        1rem;           //  13   /   14   /   15   /   16
$font-size-2xs:         .625rem;        //  8.1  /  8.75  /  9.37  /   10
$font-size-xs:          .75rem;         //  9.7  /  10.5  /  11.2  /   12
$font-size-sm:          .875rem;        // 11.3  /  12.2  /  13.1  /   14
$font-size-lg:          1.125rem;       // 14.6  /  15.7  /  16.8  /   18

$font-fixed-base:       16px;
$font-fixed-sm:         14px;

$quotation-size:        3.75rem;        // 48.7  /  52.5  /  56.2  /   60

$h1-font-size:          2.5rem;         // 32.5  /   35   /  37.5  /   40
$h2-font-size:          2rem;           //  26   /   28   /   30   /   32
$h3-font-size:          1.5rem;         // 19.5  /   21   /  22.5  /   24
$h4-font-size:          1.25rem;        // 16.2  /  17.5  /  18.7  /   20
$h5-font-size:          $font-size-lg;
$h6-font-size:          $font-size-base;

$line-height-base:      1.5;

$font-weight-normal:    400;
$font-weight-base:      $font-weight-normal;
$font-weight-bold:      700;

$headings-font-family:  $font-family-title;
$headings-font-weight:  $font-weight-bold;
$headings-line-height:  1.2;


/* BUTTONS
  --------------------------------------- */
$btn-line-height:                1.2;
$btn-font-size:                  16px;
$btn-font-size-sm:               14px;
$btn-font-family:                $font-family-base;
$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
$btn-radius:                     1px;

$btn-padding-y:                  13px;
$btn-padding-x:                  16px;
$btn-padding-y-sm:               9px;
$btn-padding-x-sm:               14px;

$btn-default-color:              $white;
$btn-default-bg:                 $brand-primary;
$btn-default-border:             $btn-default-bg;
$btn-default-color-hover:        $btn-default-color;
$btn-default-bg-hover:           $brand-secondary;
$btn-default-border-hover:       $btn-default-bg-hover;

$btn-green-color:                $white;
$btn-green-bg:                   $brand-secondary;
$btn-green-border:               $btn-green-bg;
$btn-green-color-hover:          $btn-green-color;
$btn-green-bg-hover:             $brand-primary;
$btn-green-border-hover:         $btn-green-bg-hover;

$btn-white-color:                $brand-primary;
$btn-white-bg:                   $white;
$btn-white-border:               $btn-white-bg;
$btn-white-color-hover:          $btn-white-color;
$btn-white-bg-hover:             $pale-blue-30;
$btn-white-border-hover:         $btn-white-bg-hover;

$btn-light-color:                $brand-primary;
$btn-light-bg:                   $pale-blue-30;
$btn-light-border:               $btn-light-bg;
$btn-light-color-hover:          $brand-primary;
$btn-light-bg-hover:             $pale-blue;
$btn-light-border-hover:         $btn-light-bg-hover;

$btn-lighter-color:              $brand-primary;
$btn-lighter-bg:                 $pale-blue-10;
$btn-lighter-border:             $btn-lighter-bg;
$btn-lighter-color-hover:        $brand-primary;
$btn-lighter-bg-hover:           $pale-blue-30;
$btn-lighter-border-hover:       $btn-lighter-bg-hover;

$btn-gold-color:                 $brand-primary;
$btn-gold-bg:                    $gold;
$btn-gold-border:                $btn-gold-bg;
$btn-gold-color-hover:           $gold;
$btn-gold-bg-hover:              $brand-primary;
$btn-gold-border-hover:          $btn-gold-bg-hover;

$btn-red-color:                  $white;
$btn-red-bg:                     $red;
$btn-red-border:                 $btn-red-bg;
$btn-red-color-hover:            $btn-red-color;
$btn-red-bg-hover:               $brand-primary;
$btn-red-border-hover:           $btn-red-bg-hover;

$btn-line-padding-y:             4px;
$btn-line-padding-x:             2px;
$btn-line-radius:                0;
$btn-line-width:                 0 0 2px 0;

$btn-arrow-animate-size:         4px;

$btn-social-circle-size:         40px;
$btn-social-font-size:           18px;
$btn-social-margin:              10px;

$btn-spacer:                     8px;
$btn-arrow-animate-size:         4px;


/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                $btn-font-size;
$input-font-weight:              $font-weight-base;
$input-line-height:              $btn-line-height;
$label-margin-bottom:            .5rem;
$input-padding-y:                $btn-padding-y;
$input-padding-x:                $btn-padding-x;

$custom-select-feedback-icon-padding-right: 0;
$custom-select-feedback-icon-position:      0;
$custom-select-padding-x:                   0;
$custom-select-indicator-padding:           0;

$input-bg:                       $white;
$input-disabled-bg:              $gray-light;
$input-color:                    $brand-primary;
$input-border-color:             $pale-blue;
$input-border-width:             2px;
$input-box-shadow:               none;

$input-border-radius:            $btn-radius;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-focus-border-color:       $brand-primary;
$input-focus-box-shadow:         none;

$input-placeholder-color:        $input-color;
$input-plaintext-color:          $input-color;


/* TABLES
  --------------------------------------- */
$table-cell-padding:          .5rem 1rem;
$table-cell-padding-sm:       .3rem;
$table-color:                 $brand-primary;
$table-bg:                    $pale-blue-10;
$table-accent-bg:             $pale-blue-30;
$table-border-width:          0;
$table-border-color:          $pale-blue;
$table-striped-order:         even;
$table-caption-color:         $black;
$table-head-bg:               $brand-primary;
$table-head-color:            $white;
$table-th-font-weight:        $font-weight-bold;


/* SLICK
  --------------------------------------- */
$slick-font-family: "Font Awesome 6 Duotone";
$slick-font-weight: "900";
$slick-loader-path: "./";
$slick-arrow-color: $brand-primary;
$slick-arrow-size: 20px;
$slick-dot-color: $brand-primary;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\f137";
$slick-next-character: "\f138";
$slick-prev2-character: "\10f137";
$slick-next2-character: "\10f138";
$slick-dot-character: "\2022";
$slick-dot-button-size: 10px;
$slick-dot-size: 10px;
$slick-opacity-default: 0.4;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

