a.btn,.btn {
  @include transition($link-transition);
  padding: $btn-padding-y $btn-padding-x;
  cursor: inherit;
  line-height: $btn-line-height !important;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border: none;
  border-radius: $btn-radius;
  @include hover-focus {
    color: $btn-default-color-hover;
    background-color: $btn-default-bg-hover;
    border-color: $btn-default-border-hover;
    outline: none;
    box-shadow: $btn-focus-box-shadow;
  }
  &.btn-sm {
    font-size: $btn-font-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
  &.btn-default,&.btn-primary {
    color: $btn-default-color !important;
    background-color: $btn-default-bg;
    border-color: $btn-default-border;
    @include hover-focus {
      color: $btn-default-color-hover !important;
      background-color: $btn-default-bg-hover;
      border-color: $btn-default-border-hover;
    }
  }
  &.btn-light {
    color: $btn-light-color !important;
    background-color: $btn-light-bg;
    border-color: $btn-light-border;
    @include hover-focus {
      color: $btn-light-color-hover !important;
      background-color: $btn-light-bg-hover;
      border-color: $btn-light-border-hover;
    }
  }
  &.btn-lighter {
    color: $btn-lighter-color !important;
    background-color: $btn-lighter-bg;
    border-color: $btn-lighter-border;
    @include hover-focus {
      color: $btn-lighter-color-hover !important;
      background-color: $btn-lighter-bg-hover;
      border-color: $btn-lighter-border-hover;
    }
  }
  &.btn-white {
    color: $btn-white-color !important;
    background-color: $btn-white-bg;
    border-color: $btn-white-border;
    @include hover-focus {
      color: $btn-white-color-hover !important;
      background-color: $btn-white-bg-hover;
      border-color: $btn-white-border-hover;
    }
  }
  &.btn-green,&.btn-secondary {
    color: $btn-green-color !important;
    background-color: $btn-green-bg;
    border-color: $btn-green-border;
    @include hover-focus {
      color: $btn-green-color-hover !important;
      background-color: $btn-green-bg-hover;
      border-color: $btn-green-border-hover;
    }
  }
  &.btn-gold {
    color: $btn-gold-color !important;
    background-color: $btn-gold-bg;
    border-color: $btn-gold-border;
    @include hover-focus {
      color: $btn-gold-color-hover !important;
      background-color: $btn-gold-bg-hover;
      border-color: $btn-gold-border-hover;
    }
    &.btn-search {
      @include hover-focus {
        color: $btn-green-color !important;
        background-color: $btn-green-bg;
        border-color: $btn-green-border;
      }
    }
  }
  &.btn-red {
    color: $btn-red-color !important;
    background-color: $btn-red-bg;
    border-color: $btn-red-border;
    @include hover-focus {
      color: $btn-red-color-hover !important;
      background-color: $btn-red-bg-hover;
      border-color: $btn-red-border-hover;
    }
  }
  &.btn-line {
    @extend a.lined;
    background-color: transparent;
    padding: $btn-line-padding-y $btn-line-padding-x;
    border-radius: $btn-line-radius;
    border-width: $btn-line-width;
  }
  &.btn-arrow {
    &:after {
      @include transition($link-transition-move);
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-weight: 800;
      font-family: "Font Awesome 6 Pro";
      content: "\f061";
      line-height: $btn-line-height;
      margin-left: $btn-spacer;
    }
    @include hover-focus {
      @include animate-arrow;
    }
  }
  &.btn-icon-left {
    i {
      margin-right: $btn-spacer;
    }
  }
  &.btn-icon-right {
    i {
      margin-left: $btn-spacer;
    }
  }

}
