// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
body:not(.home) {
  .main {
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','lg');
  }
/*
  &.single-post {
    .main {
      @include scaled-spacer('padding','bottom','lg');
    }
  }
*/
}
body:not(.home):not(.blog):not(.archive):not(.page-id-34) { // id-34 = Contact Us
  .main {
    @include main-column-width;
  }
}
