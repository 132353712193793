.wp-full-width {
  @include wp-full-width-no-padding;
  .action {
    @include paragraph-spacing-action;
  }
  .details {
    h1,h2,h3,h4,.h1,.h2,.h3,.h4 {
      margin-top: 0;
    }
  }
}
.vgs-block {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  @include scaled-spacer('margin','top','lg');
  @include scaled-spacer('margin','bottom','lg');
  &:not(.page-link-banners-block) {
    &+.vgs-block:not(.newsletter-form-block) {
      @include scaled-spacer-negative('margin','top','lg');
    }
  }
  &.page-link-banners-block {
    &+.vgs-block,
    &+h1,&+h2,&+h3,&+h4,&+.h1,&+.h2,&+.h3,&+.h4 {
      margin-top: 0;
    }
  }
  &+hr.wp-block-separator {
    @include scaled-spacer-negative('margin','top','lg');
  }
}
hr.wp-block-separator {
  &+.vgs-block {
    @include scaled-spacer-negative('margin','top','md');
  }
}
.hero-carousel-block {
  padding: 0;
  position: relative;
  .hero-carousel {
    @include slick-hidden;
    .container-fluid {
      &>.row {
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
        .details {
          @include clipped-details;
          article {
            h1 {
              @include media-breakpoint-up(md) {
                @include scaled-spacer('margin','top','md');
              }
            }
            .action {
              @include scaled-spacer('margin','bottom','md');
            }
          }
        }
        .media {
          @include clipped;
          height: 250px;
          @include media-breakpoint-up(sm) {
            height: 400px;
          }
          @include media-breakpoint-up(md) {
            height: auto;
            min-height: 460px;
          }
          @include media-breakpoint-up(lg) {
            min-height: 520px;
          }
          @include media-breakpoint-up(xl) {
            min-height: 560px;
          }
        }
      }
    }
  }
  .slick-arrows {
    @include scaled-absolute('bottom','md');
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    position: absolute;
    z-index: 10;
    ul {
      @include slick-arrows-ul;
      @include clipped-sizing;
    }
  }
}
.team-grid-block {
  .details {
    @include last-child-zero-margin;
    @include scaled-spacer('margin','bottom','md');
  }
  .team-grid {
    .team-member {
      margin-bottom: $grid-gutter-width-base;
      article {
        height: 100%;
        figure {
          @include figure-aspect-ratio('1/1');
          margin: 0;
        }
        .bio {
          @include last-child-zero-margin;
          padding: 1rem;
          font-size: $font-size-sm;
          h4 {
            font-size: $font-size-base;
            margin: 0 0 .5rem;
          }
        }
      }
    }
  }
}
.gallery-block {
  padding-top: 0;
  padding-bottom: 0;
  &>.container {
    position: relative;
  }
  .gallery-carousel {
    @include slick-hidden;
  }
  @include media-breakpoint-up(md) {
    .slick-slide {
      &>.row {
        flex-direction: row-reverse;
      }
    }
  }
  .media {
    min-height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(sm) {
      min-height: 315px;
    }
    @include media-breakpoint-only(md) {
      min-height: 235px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 375px;
    }
  }
  .details {
    @include scaled-spacer('padding','bottom','xl');
    article {
      @include scaled-spacer('padding','top','md');
      @include scaled-spacer('padding','bottom','md');
      @include last-child-zero-margin;
      h3 {
        @include scaled-spacer('margin','bottom','sm');
      }
      @include media-breakpoint-up(xl) {
        @include scaled-spacer('padding','top','lg');
      }
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','left','md');
      @include scaled-spacer('padding','right','md');
    }
    @include media-breakpoint-up(xl) {
      @include scaled-spacer('padding','left','lg');
      @include scaled-spacer('padding','right','lg');
      }
  }
  .slick-arrows {
    @include scaled-absolute('bottom','md');
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','left','md');
    }
    @include media-breakpoint-up(xl) {
      @include scaled-spacer('padding','left','lg');
    }
    position: absolute;
    z-index: 10;
    ul {
      @include slick-arrows-ul;
      .btn.next-slide {
        @include scaled-spacer('margin','right','sm');
      }
    }
  }

}
.testimonials-block {
  padding-top: 0;
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
    &>.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .testimonials-carousel {
    @include slick-hidden;
    .slick-dots {
      @include make-col-ready();
      @include make-col(8);
      @include make-col-offset(2);
      @include media-breakpoint-up(md) {
        @include make-col(5);
        @include make-col-offset(6);
      }
      @include scaled-absolute('bottom','md');
      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
      position: absolute;
      top: auto;
      text-align: left;
      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .media {
    min-height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(sm) {
      @include scaled-spacer('margin','top','md');
    }
    @include media-breakpoint-up(lg) {
      min-height: 280px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 340px;
    }
  }
  .details {
    @include scaled-spacer('margin','top','md');
    @include scaled-spacer('margin','bottom','md');
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','top','lg');
      @include scaled-spacer('margin','bottom','lg');
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    font-size: $font-size-lg;
    .quote {
      @include scaled-spacer('margin','bottom','sm');
      position: relative;
      font-size: $h3-font-size;
      font-style: italic;
      font-weight: $font-weight-bold;
      p:last-child {
        margin-bottom: 0;
      }
      i[class*="fa-quote-"] {
        position: absolute;
        top: .25em;
        color: $white;
        &[class*="left"] {
          left: -2em;
        }
        &[class*="right"] {
          right: -2em;
        }
      }
    }
    .attribution {
      @include scaled-spacer('margin','bottom','lg');
      @include media-breakpoint-up(md) {
        @include scaled-spacer('margin','bottom','md');
      }
    }
  }
}
.page-link-banners-block {
  margin-top: 0;
  margin-bottom: 0;
  article {
    &+article {
      @include scaled-spacer('margin','top','lg');
    }
    &:nth-child(odd) {
      &>.row {
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
      }
    }
    &>.row {
      margin: 0;
      .media {
        padding: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 230px;
        @include media-breakpoint-up(sm) {
          min-height: 370px;
        }
        @include media-breakpoint-up(md) {
          min-height: 300px;
        }
        @include media-breakpoint-up(lg) {
          min-height: 350px;
        }
        @include media-breakpoint-up(xl) {
          min-height: 420px;
        }
      }
      .details-wrap {
        position: relative;
        .details {
          @include scaled-spacer('padding','top','md');
          @include scaled-spacer('padding','bottom','md');
          @include media-breakpoint-up(sm) {
            @include scaled-spacer('padding','left','xs');
            @include scaled-spacer('padding','right','xs');
          }
          @include media-breakpoint-up(md) {
            @include scaled-spacer('padding','left','sm');
            @include scaled-spacer('padding','right','sm');
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
          @include media-breakpoint-up(lg) {
            @include scaled-spacer('padding','left','md');
            @include scaled-spacer('padding','right','md');
          }
          @include media-breakpoint-up(xl) {
            @include scaled-spacer('padding','left','lg');
            @include scaled-spacer('padding','right','lg');
          }
        }
      }
    }
  }
}
.latest-news-block {
  .titles,.action {
    text-align: center;
    margin-top: 0;
    h2 {
      margin-top: 0;
    }
  }
}
.cta-color-banner-block {
  @include scaled-spacer('margin','bottom','md');
  @include full-padding-spacer('sm');
  @include last-child-zero-margin;
  text-align: center;
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','md');
    @include scaled-spacer('padding','bottom','md');
    @include scaled-spacer('padding','left','xl');
    @include scaled-spacer('padding','right','xl');
  }
  h2 {
    margin-top: 0;
    @include resize-header('h3');
  }
}
.cta-image-banner-block {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    @include scaled-spacer('padding','left','sm');
    @include scaled-spacer('padding','right','sm');
  }
  article {
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','lg');
    @include scaled-spacer('padding','left','sm');
    @include scaled-spacer('padding','right','sm');
    @include last-child-zero-margin;
    text-align: center;
    h2 {
      margin-top: 0;
      @include scaled-spacer('margin','bottom','md');
    }
    .synopsis {
      @include scaled-spacer('margin','top','md');
    }
  }
}
.cta-tiles-block {
  .container {
    &>.row {
      justify-content: center;
    }
  }
  .cta-tile {
    .tile-link {
      @include scaled-spacer('padding','top','md');
      @include scaled-spacer('padding','bottom','md');
      @include scaled-spacer('padding','left','sm');
      @include scaled-spacer('padding','right','sm');
      @include tile-hover;
      display: block;
      height: 100%;
      text-decoration: none;
      text-align: center;
      border-top-width: 8px;
      border-top-style: solid;
      .icon {
        font-size: $h2-font-size;
        @include scaled-spacer('margin','bottom','md');
      }
      h3 {
        margin-top: 0
      }
      @include hover-focus {
        color: $brand-primary;
        h3 {
          color: $brand-primary;
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: $grid-gutter-width;
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }
  }
}
.link-list-block {
  @include scaled-spacer('margin','bottom','md');
  @include last-child-zero-margin;
  h2 {
    @include resize-header('h3');
    @include scaled-spacer('margin','bottom','sm');
    margin-top: 0;
  }
  ul {
    @include link-list;
  }
}
.newsletter-form-block {
  padding: 0;
  form {
    padding: $grid-gutter-width;
    font-size: $font-size-lg;
    &>.row {
      align-items: center;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    @include media-breakpoint-up(md) {
      @include full-padding-spacer('lg');
      .details {
        @include last-child-zero-margin;
      }
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','left','xl');
      @include scaled-spacer('padding','right','xl');
    }
    .fineprint {
      @include scaled-spacer('margin','top','md');
      @include last-child-zero-margin;
      font-size: $font-size-xs;
    }
  }
}
.accordion-block {
  .card {
    @include color-block('pale-blue-30');
    @include scaled-spacer('margin','bottom','xs');
    border: none;
    .card-header {
      padding: $grid-gutter-width/3 $grid-gutter-width/2;
      background-color: transparent;
      border: none;
      h3 {
        font-family: $font-family-sans;
        font-size: $font-size-base;
        margin: 0;
        a {
          text-decoration: none;
          display: block;
          position: relative;
          padding-right: 1.5rem;
          &:after {
            color: $black;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: 800 $font-size-lg "Font Awesome 6 Pro";
            content: "\f0d8";
          }
          &.collapsed {
            &:after {
              content: "\f0d7";
            }
          }

        }
      }
    }
    .card-body {
      @include last-child-zero-margin;
      padding: $grid-gutter-width/3 $grid-gutter-width/2 $grid-gutter-width/2;
    }
  }
}
.coverage-map-block {
  .titles {
    @include titles-wide-block;
  }
  .footnote {
    @include footnote;
    p:first-child {
      margin-top: .5em;
    }
  }
}
.gravity-form-block {
  .titles {
    text-align: center;
    h1,h2,h3,.h1,.h2,.h3 {
      margin-top: 0;
    }
  }
}
.calculator-block {
//   @include scaled-spacer('padding','bottom','sm');
//   padding-top: 0;
  .titles {
    @include titles-wide-block;
  }
  .calculator-row {
    .calculator {
      @include media-breakpoint-down(sm) {
        margin-bottom: $grid-gutter-width;
      }
      .savings-calculator {
        @include color-block('pale-blue-10');
        @include full-padding-spacer('md');
        @include last-child-zero-margin;
        height: 100%;
        &>section {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
        .form-group {
          @include scaled-spacer('margin','bottom','md');
          @include make-row();
          @include media-breakpoint-up(lg) {
            align-items: center;
          }
          .calculator-question {
            @include make-col-ready();
            @include make-col(12);
            margin-bottom: .5em;
            line-height: $headings-line-height;
            @include media-breakpoint-up(lg) {
              @include make-col(8);
              margin-bottom: 0;
            }
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
          }
          .calculator-answer {
            @include make-col-ready();
            @include make-col(12);
            @include media-breakpoint-up(lg) {
              @include make-col(4);
            }
          }
        }
        p.action {
          margin-bottom: 0;
          text-align: right;
        }
      }
    }
    .calculator-results-wrap {
      .calculator-results {
        @include color-block('pale-blue-30');
        @include full-padding-spacer('md');
        font-size: $font-size-lg;
        height: 100%;
        h3 {
          @include scaled-spacer('margin','bottom','sm');
          margin-top: 0;
        }
        ul {
          @include list-normalize;
          @include scaled-spacer('margin','bottom','sm');
          &:before {
            margin-bottom: .5em;
            display: block;
            content: attr(aria-label);
            font-weight: $font-weight-bold;
          }
          li {
            @include make-row();
            margin-bottom: .5em;
            span {
              @include make-col-ready();
              @include make-col(6);
              &.data {
                text-align: right;
              }
            }
          }
        }
        #charts {
          display: none;
          visibility: hidden;
          height: 0;
        }
      }
    }
    &.rng-row {
      @include scaled-spacer('margin','top','md');
      &:first-child {
        margin-top: 0;
      }
      h3 {
        @include scaled-spacer('margin','bottom','xs');
        margin-top: 0;
      }
      small {
        @include scaled-spacer('margin','bottom','md');
        display: block;
      }
    }
  }
  .calculator-data-source {
    h4 {
      @include scaled-spacer('margin','bottom','xs');
    }
    .footnote {
      @include footnote;
    }
  }
}
