.grid-articles {
  &>article {
    @include scaled-spacer('margin','bottom','md');
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    .tile-link {
      @include tile-img-hover;
      height: 100%;
      display: block;
      text-decoration: none;
      figure {
        @include scaled-spacer('margin','bottom','sm');
      }
      .meta-data {
        @include scaled-spacer('margin','bottom','sm');
        @include meta-text;
        color: $brand-primary;
        .date {
          text-align: right;
        }
      }
      h3 {
        @include resize-header('h4');
        margin-top: 0;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
          width: 85%;
        }
      }
      .meta-data,h3 {
        @include transition($link-transition);
      }
      @include hover-focus {
        .meta-data,h3 {
          color: $green;
        }
      }
    }
  }
  &:not(.latest) {
    @include scaled-spacer('margin','bottom','md');
    &>article {
      @include scaled-spacer('margin','bottom','lg');
      @include media-breakpoint-up(lg) {
        @include make-col(4);
        .tile-link {
          h3 {
            @include resize-header('h5');
            font-family: $font-family-sans;
            width: 100%;
          }
        }
      }
    }
  }
}
.blog {
  a.featured-post {
    @include color-block('blue');
    @include scaled-spacer('margin','bottom','lg');
    @include tile-hover;
    display: block;
    text-decoration: none;
    &>.row {
      margin-left: 0;
      margin-right: 0;
    }
    .details {
      position: relative;
      article {
        @include scaled-spacer('padding','top','sm');
        @include scaled-spacer('padding','bottom','sm');
        @include media-breakpoint-up(md) {
          @include full-padding-spacer('md');
        }
        @include media-breakpoint-up(lg) {
          @include full-padding-spacer('lg');
        }
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .meta-data {
          @include scaled-spacer('margin','bottom','sm');
          @include meta-text;
          .date {
            text-align: right;
          }
        }
        h3 {
          @include scaled-spacer('margin','bottom','sm');
          margin-top: 0;
          font-family: $font-family-sans;
        }
        .entry-summary {
          @include scaled-spacer('margin','bottom','md');
        }
      }
    }
    .media {
      min-height: 250px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(sm) {
        min-height: 315px;
      }
      @include media-breakpoint-only(md) {
        min-height: 235px;
      }
      @include media-breakpoint-up(xl) {
        min-height: 375px;
      }
    }
  }
}
nav.pagination {
  @include scaled-spacer('margin','bottom','lg');
  justify-content: center;
  font-weight: $font-weight-bold;
  color: $brand-primary;
  span {
    margin-left: .25em;
    margin-right: .25em;
  }
  a {
    i {
      &:before,&:after {
        @include transition($link-transition);
        opacity: 1;
      }
      &:before {
        color: $brand-primary;
      }
      &:after {
        color: transparent;
      }
    }
    @include hover {
      i {
        &:before {
          color: transparent;
        }
        &:after {
          color: $brand-primary;
        }
      }
    }
  }
}
