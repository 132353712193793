.content-info {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  color: $brand-primary;
  font-size: $font-fixed-sm;
  line-height: $headings-line-height;
  .brand {
    @include scaled-spacer('margin','bottom','md');
    a {
      display: block;
      max-width: 85px;
    }
  }
  p {
    margin-bottom: $font-fixed-base*1.5;
  }
  .message {
    @include media-breakpoint-down(md) {
      @include scaled-spacer('margin','top','md');
    }
    font-weight: $font-weight-bold;
    p {
      margin-bottom: 5px;
    }
  }
  .social {
    @include scaled-spacer('margin','bottom','md');
    .nav-social {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
  }
  // reorder columns on desktop
  @include media-breakpoint-up(lg) {
    .brand {      order: 1; }
    .social {     order: 5; }
    .contact-1 {  order: 2; }
    .contact-2 {  order: 3; }
    .contact-3 {  order: 4; }
    .message {    order: 6; }
    .copyright {  order: 7; }
  }
}
.nav-social {
  ul {
    @include list-normalize;
    li {
      display: inline-block;
      margin-right: $btn-social-margin;
      &:last-child {
        margin-right: 0;
      }
      a {
        background-color: $pale-blue-30;
        color: $brand-primary;
        font-size: $btn-social-font-size;
        display: block;
        height: $btn-social-circle-size;
        width: $btn-social-circle-size;
        border-radius: 50%;
        text-align: center;
        line-height: $btn-social-circle-size;
        @include hover-focus {
          background-color: $brand-primary;
          color: $white;
        }
      }
    }
  }
}
