.search-results-list {
  @include link-list;
  li {
    @include scaled-spacer('margin','bottom','sm');
    .title {
      @include last-child-zero-margin;
      font-weight: $font-weight-base;
      h4 {
        margin: 0 0 5px;
      }
    }
    a {
      @include hover-focus {
        h4 {
          color: $brand-primary;
        }
      }
    }
  }
}
