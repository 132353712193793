/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $overline, $headline, $link, $hover, $line, $linetext) {
  $map: (
    bg:       $bg,
    text:     $text,
    overline: $overline,
    headline: $headline,
    link:     $link,
    hover:    $hover,
    line:     $line,
    linetext: $linetext
  );
  @return $map;
}


/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(xs)*gutter-scale($scale);
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: gutter-height(md)*gutter-scale($scale); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: gutter-height(lg)*gutter-scale($scale); }
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: -(gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: -(gutter-height(lg)*gutter-scale($scale)); }
}
@mixin scaled-absolute($csslocation,$scale) {
  #{$csslocation}: (gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csslocation}: (gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csslocation}: (gutter-height(lg)*gutter-scale($scale)); }
}
@mixin scaled-absolute-negative($csslocation,$scale) {
  #{$csslocation}: -(gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(md) { #{$csslocation}: -(gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csslocation}: -(gutter-height(lg)*gutter-scale($scale)); }
}

@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}

/* COLORS
  --------------------------------------- */

/*  1 name          2 bgcolor         3 text      4 overline    5 headline    6 link color    7 hover color     8 line color      9 line text */
$color-list: (
    white           $white            $body-color $blue         $blue         $blue           $green            $pale-blue-30     $blue,
    black           $black            $white      $white        $white        $white          $pale-blue-10     $pale-blue-30     $white,
    blue            $blue             $white      $white        $white        $white          $pale-blue-10     $pale-blue        $white,
    green           $green            $white      $white        $white        $white          $pale-blue-10     $pale-blue-30     $white,
    gray            $gray             $white      $white        $white        $white          $pale-blue-10     $pale-blue-30     $white,
    gold            $gold             $blue       $blue         $blue         $black          $blue             $pale-blue-30     $black,
    red             $red              $white      $white        $white        $white          $pale-blue-10     $pale-blue-30     $white,
    teal            $teal             $white      $white        $white        $white          $pale-blue-10     $pale-blue-30     $white,
    pale-blue       $pale-blue        $blue       $blue         $blue         $blue           $black            $pale-blue-30     $blue,
    pale-blue-30    $pale-blue-30     $blue       $blue         $blue         $blue           $black            $pale-blue-30     $blue,
    pale-blue-10    $pale-blue-10     $blue       $blue         $blue         $blue           $black            $pale-blue-30     $blue,
);

$color-map: ();

@each $value in $color-list {
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    .overline {
      color: #{nth($value, 4)};
    }
    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
      color: #{nth($value, 5)};
    }
    a {
      color: #{nth($value, 6)};
      @include hover-focus {
        color: #{nth($value, 7)};
      }
    }
    a.lined,.nav-lined a {
      color: #{nth($value, 9)};
      border-color: #{nth($value, 8)};
      @include hover-focus {
        border-color: #{nth($value, 6)};
      }
    }
    &.tile-link,.tile-link,&.link-list-item,.link-list-item,&.featured-post {
      span.lined {
        color: #{nth($value, 9)};
        border-color: #{nth($value, 8)};
        @include transition($link-transition);
      }
      h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
        @include transition($link-transition);
      }
      @include hover-focus {
        span.lined {
          border-color: #{nth($value, 6)};
        }
        h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
          color: #{nth($value, 7)};
        }
      }
    }
  }
  .accent-#{nth($value, 1)} {
    border-color: #{nth($value, 2)};
    div.icon {
      i {
        color: #{nth($value, 2)};
      }
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)},#{nth($value, 9)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  .overline {
    color: map-deep-get($color-map, $block-color, "overline");
  }
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  a {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
  }
  a.lined,.nav-lined a {
    color: map-deep-get($color-map, $block-color, "linetext");
    border-color: map-deep-get($color-map, $block-color, "line");
    @include hover-focus {
      border-color: map-deep-get($color-map, $block-color, "hover");
    }
  }
  &.tile-link,.tile-link,&.link-list-item,.link-list-item,&.featured-post {
    span.lined {
      color: map-deep-get($color-map, $block-color, "linetext");
      border-color: map-deep-get($color-map, $block-color, "line");
      @include transition($link-transition);
    }
    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
      @include transition($link-transition);
    }
    @include hover-focus {
      span.lined {
        border-color: map-deep-get($color-map, $block-color, "link");
      }
      h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
        color: map-deep-get($color-map, $block-color, "hover");
      }
    }
  }
}
@mixin vgs-gradient {
  @include color-block('white');
  background: linear-gradient(270deg, $pale-blue-10 66.18%, $pale-blue-30 100%);
  color: $blue;
  a {
    @include hover-focus {
      color: $black;
    }
  }
}
@mixin vgs-gradient-green {
  @include color-block('white');
  background: linear-gradient(270deg, #fafdfb 66.18%, #e6f5ed 100%)
}

.bg-gradient {
  @include vgs-gradient;
}
.bg-gradient-green {
  @include vgs-gradient-green;
}
@mixin btn-style($style) {
  color: $btn-#{$style}-color;
  background-color: $btn-#{$style}-bg;
  border-color: $btn-#{$style}-border;
  @include hover-focus {
    color: $btn-#{$style}-color-hover;
    background-color: $btn-#{$style}-bg-hover;
    border-color: $btn-#{$style}-border-hover;
  }
}

/* FONTS
  --------------------------------------- */
@mixin overline {
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  text-transform: uppercase;
  line-height: $line-height-base;
  letter-spacing: .1em;
}
@mixin meta-text {
  @include overline;
}
@mixin meta-text-xs {
  @include meta-text;
  font-size: $font-size-xs;
}
@mixin meta-text-2xs {
  @include meta-text;
  font-size: $font-size-2xs;
}
@mixin footnote {
  @include last-child-zero-margin;
  font-size: $font-size-xs;
  color: rgba($body-color,8);
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size
);
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}
@mixin heading-treatment {
  line-height: $headings-line-height;
  letter-spacing: -.02em;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
}
@mixin paragraph-spacing {
  margin-bottom: 1.5rem;
}
@mixin paragraph-spacing-md {
  @include scaled-spacer('margin','bottom','md');
}
@mixin paragraph-spacing-action {
  @include scaled-spacer('margin','top','md');
  margin-bottom: 0;
}
@mixin wp-block-spacing {
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','md');
}


@mixin animate-arrow {
  &:after {
    transform: translateX($btn-arrow-animate-size);
  }
}
@mixin nav-list-header {
  @include resize-header('h3');
  line-height: $headings-line-height;
  font-weight: $font-weight-bold;
}


/* ELEMENTS
  --------------------------------------- */

@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  isolation: isolate;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}

@mixin select-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-image: url(../images/chevron-down-solid.svg);
  background-size: auto 1em;
  padding-right: 2em !important;
}
@mixin list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin list-no-indent {
  padding: 0 0 0 1.5rem;
}
@mixin list-md-indent {
  padding: 0 0 0 1.75rem;
}
@mixin last-child-zero-margin {
  :last-child {
    margin-bottom: 0;
  }
}
@mixin img-fluid {
  max-width: 100%;
  height: auto;
}
@mixin btn-sm {
  font-size: $btn-font-size-sm;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
}
@mixin clipped {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include media-breakpoint-down(sm) {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
  @include media-breakpoint-up(md) {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  }
}
@mixin clipped-sizing {
  position: relative;
  @include media-breakpoint-up(sm) {
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    max-width: 540px;
  }
  @include media-breakpoint-up(md) {
    max-width: 345px; // half MD(720) - 15
    padding-right: 0;
    margin-right: inherit;
  }
  @include media-breakpoint-up(lg) {
    max-width: 425px; // half LG(960) - gutter-pad(15) - 1/2 column width(40)
    margin-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 513px; // half XL(1152) - gutter-pad(15) - 1/2 column width(48)
    margin-right: 48px;
  }
}
@mixin clipped-details {
  position: relative;
  article {
    @include scaled-spacer('padding','top','md');
    @include scaled-spacer('padding','bottom','lg');
//     @include last-child-zero-margin;
    @include clipped-sizing;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('padding','bottom','md');
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
@mixin clipped-details-post {
  position: relative;
  article {
    @include scaled-spacer('padding','top','md');
    @include scaled-spacer('padding','bottom','md');
    @include last-child-zero-margin;
    position: relative;
    @include media-breakpoint-up(sm) {
      margin-left: auto;
      margin-right: auto;
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
      max-width: 540px;
    }
    @include media-breakpoint-up(md) {
      max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','top','lg');
      @include scaled-spacer('padding','bottom','lg');
      max-width: 425px; // half LG(960) - gutter-pad(15) - 1/2 column width(40)
      padding-right: 0;
      margin-right: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
    @include media-breakpoint-up(xl) {
      max-width: 513px; // half XL(1152) - gutter-pad(15) - 1/2 column width(48)
      margin-right: 48px;
    }
  }
}
@mixin titles-wide-block {
  @include main-column-width;
  text-align: center;
  h1,h2,h3,.h1,.h2,.h3 {
    margin-top: 0;
  }
}
@mixin slick-hidden {
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
}
@mixin slick-arrows-ul {
  @include list-normalize;
  color: $brand-primary;
  li {
    display: inline-block;
    margin: 0;
    &.slick-count {
      vertical-align: middle;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      margin: 0 .25em 0 .5em;
      min-width: 3em;
      text-align: center;
    }
  .slick-arrow:not(.next-slide) {
      position: relative;
      height: $slick-arrow-size;
      width: $slick-arrow-size;
      line-height: 0px;
      font-size: 0px;
      cursor: pointer;
      background: transparent;
      color: transparent;
      border: none;
      &:before {
        color: $slick-arrow-color;
      }
      &:after {
        color: transparent;
      }
      &:before,&:after {
        @include transition($link-transition);
        font-family: $slick-font-family;
        font-weight: $slick-font-weight;
        font-size: $slick-arrow-size;
        line-height: 1;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.25em;
        text-align: center;
      }
      @include hover {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
          color: transparent;
        }
        &:after {
          color: $slick-arrow-color;
        }
      }
      &.prev {
        &:before {
          content: $slick-prev-character;
        }
        &:after {
          content: $slick-prev2-character;
        }
      }

      &.next {
        &:before {
          content: $slick-next-character;
        }
        &:after {
          content: $slick-next2-character;
        }
      }

    }
  }
}
@mixin tile-hover {
  transition: all ease-in .1s;
  @include hover-focus {
    transform: scale(1.015);
  }
}
@mixin tile-img-hover {
  figure {
    @include color-block('gray');
    overflow: hidden;
    img {
      transition: all ease-in .1s;
    }
  }
  @include hover-focus {
    figure {
      img {
        transform: scale(1.015);
        opacity: .98;
      }
    }
  }
}
@mixin link-list {
  @include list-normalize;
  li {
    margin-bottom: 4px;
    a {
      @include color-block('pale-blue-10');
      border-radius: $btn-radius;
      padding: $grid-gutter-width/3 $grid-gutter-width/2;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      font-weight: $font-weight-bold;
      @include hover-focus {
        @include color-block('pale-blue-30');
        h4 {
          color: $brand-primary !important;
        }
      }
      .lined {
        @include scaled-spacer('margin','left','sm');
        white-space: nowrap;
      }
    }
  }
}


/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}

