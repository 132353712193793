a#skip-link {
  position:fixed;
  left:-999px;
  top: 0;
  z-index:1001;
  width:1px;
  height:1px;
  overflow:hidden;
  &:active, &:focus {
    top:0;
    left: 0;
    width: auto;
    height: auto;
    overflow:auto;
  }
}
header.banner {
  .alertbar {
    min-height: $alertbar-min-height;
    display: none;
    .alertbar-engaged & {
      display: block;
    }
    .alertbar-message {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: $alertbar-min-height/5;
      margin-bottom: $alertbar-min-height/5;
      p {
        margin: 0 $alertbar-dismiss-width;
      }
      .closealert {
        position: absolute;
        right: $grid-gutter-width/2;
        top: 50%;
        transform: translateY(-50%);
        text-decoration: none;
      }
    }
  }
  position: fixed;
  width: 100%;
  height: $header-height-sm;
  left: 0;
  top: 0;
  z-index: 100;
  .primary-block {
    @include color-block('white');
    border-bottom: 1px solid $pale-blue-30;
    align-items: center;
    height: ($header-height-sm);
    &>.container {
      align-items: center;
      &>.row {
        align-items: center;
      }
    }
  }
  @include media-breakpoint-up(md) {
    height: $header-height;
    .primary-block {
      height: ($header-height);
    }
  }
  .logged-in.admin-bar & {
    top: $adminbar-height;
    @media screen and (max-width: 782px) {
      top: $adminbar-height-sm;
    }
  }
  .logo {
    .brand {
      display: inline-block;
      max-width: 120px;
    }
  }
  ul.phone-numbers {
    @include list-normalize;
    li {
      @include scaled-spacer('margin','left','md');
      @include media-breakpoint-up(xl) {
        @include scaled-spacer('margin','left','md');
      }
      flex-direction: column;
      align-items: center;
      .btn {
        @include btn-sm;
        padding-top: 6px;
        padding-bottom: 6px;
        .text-sm {
          display: none;
        }
      }
      &>span {
        @include meta-text-2xs;
        color: $brand-primary;
        padding-bottom: 2px;
/*
        max-width: 59px;
        @include media-breakpoint-up(lg) {
          max-width: 65px;
        }
*/
      }
      &.phone-1,&.phone-2 {
        &>span {

        }
      }
      &.phone-2 {
        &>span {
/*
          max-width: 84px;
          @include media-breakpoint-up(lg) {
            max-width: 92px;
          }
*/
        }
      }
    }
  }
  ul.primary-links {
    @include list-normalize;
    margin-left: auto;
    li {
      @include scaled-spacer('margin','left','sm');
      align-items: center;
      &:first-child {
        margin-left: 0;
      }
      .btn {
        @include btn-sm;
      }
    }
  }
  #PrimaryNavigationToggle {
    min-width: 98px;
    @include media-breakpoint-up(md) {
      min-width: 86px;
    }
    span {
      &.opened {
        display: none;
      }
      &.unopened {
        display: inline-block;
      }
    }
    .primary-nav-engaging & {
      span {
        &.opened {
          display: inline-block;
        }
        &.unopened {
          display: none;
        }
      }
    }
  }
  #PrimaryNavigation {
    position: relative;
    margin: 0;
    .PrimaryNavigationWrapper {
      @include color-block('blue');
      padding: 0;
      nav.nav-primary {
        @include scaled-spacer('padding','top','sm');
        @include media-breakpoint-up(md) {
          @include full-padding-spacer('md');
/*
          @include scaled-spacer('padding','top','md');
          @include scaled-spacer('padding','bottom','lg');
          @include scaled-spacer('padding','right','lg');
          @include scaled-spacer('padding','left','lg');
*/
        }
        @include media-breakpoint-up(lg) {
          @include full-padding-spacer('lg');
//           @include scaled-spacer('padding','top','lg');
        }
        .search-form {
          @include scaled-spacer('margin','bottom','sm');
          @include media-breakpoint-up(lg) {
            @include scaled-spacer('margin','bottom','md');
          }
        }
        ul.mobile-buttons {
          @include list-normalize;
          @include make-row();
          @include scaled-spacer('margin','bottom','xs');
          padding-left: $grid-gutter-width*.25;
          padding-right: $grid-gutter-width*.25;
          li {
            @include make-col-ready();
            @include make-col(6);
            @include scaled-spacer('margin','bottom','sm');
            padding-left: $grid-gutter-width*.25;
            padding-right: $grid-gutter-width*.25;
            .btn {
              width: 100%;
              padding-left: 1px;
              padding-right: 1px;
            }
            .btn-default {
              @extend .btn-white;
            }
            .btn-lighter {
              @extend .btn-light;
            }
            &.phone-1,&.phone-2 {
              .btn {
                .text-lg {
                  display: none;
                }
              }
              &>span {
                display: none !important;
              }
            }
          }
        }
        .nav-social {
          @include scaled-spacer('margin','bottom','md');
          ul {
            li {
              a {
                color: $brand-primary;
                @include hover-focus {
                  background-color: $pale-blue;
                  color: $brand-primary;
                }
              }
            }
          }
        }

        ul#menu-primary-navigation {
          @include make-row();
          font-size: $font-fixed-base;
          &>li {
            @include scaled-spacer('margin','bottom','lg');
            @include make-col-ready();
            @include make-col(12);
            @include media-breakpoint-up(md) {
              @include make-col(6);
            }
            @include media-breakpoint-up(lg) {
              @include make-col(4);
            }
            &>.nav-heading {
              @include nav-list-header;
              display: block;
              border-bottom: 2px solid $pale-blue;
              padding-bottom: 5px;
            }
            &>.subnav-toggle {
              @include nav-list-header;
              display: none;
            }
            &>ul.sub-menu {
              @include list-normalize;
              margin: 10px 0 10px -8px;
              @include media-breakpoint-up(md) {
                display: block !important;
              }
              &>li {
                line-height: $headings-line-height;
                margin: 2px 0;
                a {
                  display: inline-block;
                  padding: 5px 8px;
                  font-size: $font-fixed-base;
                  text-decoration: none;
                  @include hover-focus {
                    background-color: $blue-dark;
                    border-radius: 3px;
                  }
                }
                &.active {
                  a {
                    color: $pale-blue-10;
                    background-color: $blue-dark;
                    border-radius: 3px;
                    text-decoration: none !important;
                  }
                }
              }

            }
          }
          @include media-breakpoint-down(sm) {
            margin-bottom: $grid-gutter-width;
            padding-left: $grid-gutter-width*.5;
            padding-right: $grid-gutter-width*.5;
            &>li {
              padding: $grid-gutter-width*.4 0;
              margin: 0;
              border-top: 2px solid $pale-blue;
              &>.nav-heading {
                display: none;
              }
              &>.subnav-toggle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                i {
                  @include transition($link-transition-med);
                }
              }
              &>ul.sub-menu {
                display: none;
                margin: 0;
                li {
                  margin-left: -8px;
                  &:first-child {
                    margin-top: 1em;
                  }
                }
              }
              &.active-open {
                @include transition($link-transition-pad);
                padding-bottom: $grid-gutter-width*.6;
                &>.subnav-toggle {
                  i {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
body.primary-nav-engaged {
  overflow: hidden;
  header.banner {
    height: 100%;
    overflow: hidden;
    #PrimaryNavigation {
      height: calc(100% - #{$header-height-sm});
      @include media-breakpoint-up(md) {
        height: calc(100% - #{$header-height});
      }
      .PrimaryNavigationWrapper {
        height: 100%;
        overflow: scroll;
      }
    }
  }
}
