// Search form
.search-form label {
  font-weight: normal;
}
.form-control {
  font-size: $input-font-size;
	&::-webkit-input-placeholder {
	  font-style: italic;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  font-style: italic;
	}
	&::-moz-placeholder {  /* Firefox 19+ */
	  font-style: italic;
	}
	&:-ms-input-placeholder {
	  font-style: italic;
	}
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
select.form-control {
  @include select-control;
}

.gform_wrapper.gravity-theme {
  .gfield_required {
    color: $red;
  }
  .gfield_label {
    @include meta-text;
    color: $brand-primary;
    margin-bottom: 4px;
  }
  .gfield {
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),select,textarea {
      @extend .form-control;
    }
    textarea,textarea.large {
      height: 120px;
    }
    select {
      @include select-control;
    }
    .ginput_container_checkbox {
      #input_3_17 {
        @include make-row();
        .gchoice {
          @include make-col-ready();
          @include make-col(4);
          text-align: center;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    &.gfield_error {
      input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),select,textarea {
        border-color: $red;
      }
      .gfield_checkbox {
        label {
          color: $body-color;
        }
      }
    }
  }
  .gform_footer,.gform_page_footer {
    button,input[type=submit] {
      @extend .btn;
    }
  }
}
