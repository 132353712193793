
@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}


/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: $slick-arrow-size;
  width: $slick-arrow-size;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
//   top: 50%;
/*
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
*/
  padding: 0;
  border: none;
  outline: none;
  &:before {
    color: $slick-arrow-color;
  }
  &:after {
    color: transparent;
  }
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      color: transparent;
    }
    &:after {
      color: $slick-arrow-color;
    }
  }
  &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
  }
  &:before,&:after {
    @include transition($link-transition);
    font-family: $slick-font-family;
    font-weight: $slick-font-weight;
    font-size: $slick-arrow-size;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25em;
    text-align: center;
//     opacity: $slick-opacity-default;
/*
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
*/
  }
}

.slick-prev {
  left: 0;
  &:before {
    content: $slick-prev-character;
  }
  &:after {
    content: $slick-prev2-character;
  }
}

.slick-next {
  right: -25px;
  &:before {
    content: $slick-next-character;
  }
  &:after {
    content: $slick-next2-character;
  }
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-button-size;
    width: $slick-dot-button-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      @include transition($link-transition);
      border: 2px solid $slick-dot-color;
      border-radius: 50%;
      background: transparent;
      display: block;
      height: $slick-dot-button-size;
      width: $slick-dot-button-size;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      opacity: $slick-opacity-default;
      &:hover, &:focus {
        outline: none;
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-active button {
      opacity: $slick-opacity-on-hover;
      background-color: $slick-dot-color;
    }
  }
}
