table#water-heating-table {
  thead {
    th {
      &:first-child,&:last-child {
        border-radius: 0 !important;
      }
    }
    &.th-form {
      td {
        background-color: transparent !important;
      }
    }
    th,td {
      background-color: $table-head-bg;
      color: $table-head-color;
      font-weight: $table-th-font-weight;
    }
  }
  tbody {
    td {
      vertical-align: middle;
    }
  }
  td.cost-unit,td.energy-factor {
    white-space: nowrap;
    input {
      display: inline-block;
      max-width: 65px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
table#home-heating-table {
  tbody {
    tr {
      border-top: 1px solid $blue;
      vertical-align: middle;
      &[id*=hid] {
        display: none;
      }
      &:nth-child(4n),&:nth-child(4n - 1) {
        background-color: white;
      }
      &.cohRowDetails {
        border-top: none;
        p.asmpHid {
          margin-bottom: .5em;
          font-weight: bold;
        }
        label {
          display: block;
        }
        input[type=text] {
          margin-right: .5em;
        }
      }
      &.cohRow {
        input[type=text] {
          max-width: 100px;
        }
      }
      td {
        vertical-align: middle;
        label {
          margin-bottom: 0;
        }
        .form-control {
          width: auto;
          display: inline-block;
          &[type=text] {
            &:read-only {
              background-color: transparent;
              border-top: none;
              border-left: none;
              border-right: none;
              border-bottom-width: 1px;
              color: $red;
            }
          }
        }
      }
    }
  }
}
